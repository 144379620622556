import gifLoading from '../../../../img/carregando-unscreen.gif';
import gifChecked from '../../../../img/carregando-checked.gif';
import { Modal, ModalContent } from 'semantic-ui-react';
import './ModalLoading.css';
import { useState, useEffect } from 'react';

function ModalLoading({ sending, checked }) {
    const phrases = [
        "Carregando dados...",
        "Quase pronto...",
        "Só mais um instante...",
        "Carregamento em andamento...",
        "Estamos processando...",
        "Aguarde um momento...",
        "Finalizando..."
    ];

    const [currentPhrase, setCurrentPhrase] = useState(0);
    const [isComplete, setIsComplete] = useState(false);
    const [isFadeLoading, setIsFadeLoading] = useState(false);

    useEffect(() => {
        if (!checked) {
            const interval = setInterval(() => {
                setCurrentPhrase(prev => (prev + 1) % phrases.length);
            }, 2500);
            return () => clearInterval(interval);
        }
    }, [phrases.length, checked]);

    const startCompletionProcess = () => {
        setTimeout(() => {
            setIsComplete(true);
        }, 2000);
        setIsFadeLoading(true);
    };

    useEffect(() => {
        if (sending) {
            startCompletionProcess();
        }
    }, [sending]);

    console.log(checked)
    return (
        <Modal open={true} style={{ width: '350px', height: '250px' }}>
            <ModalContent>
                <div className="container-modal-loading">
                    {checked ? (
                        <div className='container-gif fade-in'>
                            <img className={`gif-checked fade-in`} src={gifChecked} alt="Sucesso" />
                        </div>
                    ) : (
                        <div className='container-gif fade-in'>
                            <img className={`gif ${checked ? 'fade-out' : ''}`} src={gifLoading} alt="loading..." />
                        </div>
                    )}
                    {!checked ? (
                        <div className={`phrases ${checked ? 'fade-out' : ''}`}>
                            <span key={currentPhrase} className={`phrase ${checked ? 'fade-out' : ''}`}>
                                {phrases[currentPhrase]}
                            </span>
                        </div>
                    ) : (
                        <div className="success-message fade-in">Envio feito com sucesso!</div>
                    )}

                </div>
            </ModalContent>
        </Modal>
    );
}

export default ModalLoading;
