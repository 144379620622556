import { BrowserView, MobileView } from 'react-device-detect';
import Footer from "../../Footer/Footer";
import NavBar from "../../Navbar/NavBar";
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
import './ProtocoloCriado.css';

function ProtocoloCriado(props) {
    const { form_dados } = props;
    const navigate = useNavigate(); // Hook para navegação

    const handleAcompanhamento = () => {
        navigate('/assistencia/acompanhamento');
    };

    return (
        <>
            <BrowserView>
                <div id="topo" />
                <NavBar />
                <div className="protocolo-container">
                    <div>
                        <h2>Obrigado. Sua assistência foi criada!</h2>
                        <p>Seu protocolo é <b>{form_dados[0].protocolo}</b></p>
                        {/* Botão de acompanhamento abaixo do número do protocolo */}
                        <button className="acompanhamento-button" onClick={handleAcompanhamento}>
                            Acompanhar Assistências
                        </button>
                    </div>
                </div>
                <Footer />
            </BrowserView>

            <MobileView>
                <div id="topo" />
                <NavBar mobile />
                <div className="protocolo-container">
                    <div>
                        <h2>Obrigado. Sua assistência foi criada!</h2>
                        <p>Seu protocolo é <b>{form_dados[0].protocolo}</b></p>
                        {/* Botão de acompanhamento para versão mobile */}
                        <button className="acompanhamento-button" onClick={handleAcompanhamento}>
                            Acompanhar Assistências
                        </button>
                    </div>
                </div>
                <Footer mobile />
            </MobileView>
        </>
    );
}

const mapStateToProps = state => ({
    form_dados: state.FormReducer.form_dados,
});

export default connect(mapStateToProps, {})(ProtocoloCriado);
