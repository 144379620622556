import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import NavBar from "../../Navbar/NavBar";
import { RespostaClienteAction, DetalhesProtocoloAction, DetalhesProtocoloClearAction } from "../../../actions/AcompanhamentoActions.js"
import Footer from "../../Footer/Footer";
import { Form, Segment, Icon } from 'semantic-ui-react';

import { connect } from 'react-redux';
import DropzoneComponent from '../../pages/Assistencia/formularios/Dropzone.js'
import './DetalhesProtocolo.css';
import { BrowserView, MobileView } from 'react-device-detect';

const getStatusClass = (status) => {
    switch (status) {
        case 1:
            return 'status-em-analise';
        case 2:
            return 'status-aguardando-cliente';
        case 3:
            return 'status-aguardando-tecnico';
        case 4:
            return 'status-procedente';
        case 5:
            return 'status-improcedente';
        default:
            return 'status-aguardando-analise';
    }
};

function DetalhesProtocolo(props) {
    const { numeroProtocolo } = useParams();
    const navigate = useNavigate();

    const { RespostaClienteAction, DetalhesProtocoloAction, DetalhesProtocoloClearAction } = props;//actions
    const { detalhesprotocolo, loadingResposta, itens_nota } = props; // reducer

    const [mensagem, setMensagem] = useState('');
    const [isErro, setIsErro] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);


    const handleBackClick = () => {
        if (itens_nota.length > 0) {
            navigate('/assistencia/acompanhamento');
        } else {
            DetalhesProtocoloClearAction();
            navigate('/');
        }
    };


    const handleTextareaChange = (e) => {
        setMensagem(e.target.value);
        if (e.target.value.trim() !== '') {
            setIsErro(false); // Remove o erro quando algo é digitado
        }
    };

    const handleEnviarClick = () => {
        if (mensagem.trim() === '') {
            setIsErro(true); // Adiciona erro se o campo estiver vazio
        } else {
            const dados = { resposta: mensagem, assistenciaid: detalhesprotocolo.DataSource1[0].assistencia_id, status: 3, assistencia_tipo: detalhesprotocolo.DataSource1[0].assistencia_tipo }
            RespostaClienteAction(dados, uploadedFiles).then(() => {
                setMensagem(''); // Limpa o campo após o envio
                setIsErro(false); // Remove o erro
                if (itens_nota.length > 0) {
                    navigate('/assistencia/acompanhamento');
                } else {
                    navigate('/');
                }

            })


        };
    }

    useEffect(() => {
        DetalhesProtocoloAction(numeroProtocolo)
        //eslint-disable-next-line
    }, [numeroProtocolo]);

    return (
        <>
            <BrowserView>
                <div id="topo" />
                <NavBar />
                <div className="detalhes-protocolo-container">
                    <h1>Detalhes do Protocolo</h1>
                    <div className="info-grid">
                        <div className="info-card">
                            <h2>Número do Protocolo:</h2>
                            <p>{numeroProtocolo}</p>
                        </div>
                        <div className="info-card">
                            <h2>Nome do Produto:</h2>
                            <p>{detalhesprotocolo?.DataSource1?.[0]?.produto}</p>
                        </div>
                        <div className="info-card">
                            <h2>Número da Nota Fiscal:</h2>
                            <p>{detalhesprotocolo?.DataSource1?.[0]?.numnota}</p>
                        </div>
                        <div className="info-card">
                            <h2>Data de Abertura:</h2>
                            <p>
                                {new Date(detalhesprotocolo?.DataSource1?.[0]?.dtcriacao).toLocaleDateString('pt-BR', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                })}
                            </p>
                        </div>
                        <div className="info-card">
                            <h2>Status:</h2>
                            <p>
                                <span className={`status-label ${getStatusClass(detalhesprotocolo?.DataSource1?.[0]?.status)}`}>
                                    {detalhesprotocolo?.DataSource1?.[0]?.status === 0 ? 'Aguardando Análise' :
                                        detalhesprotocolo?.DataSource1?.[0]?.status === 1 ? 'Em análise' :
                                            detalhesprotocolo?.DataSource1?.[0]?.status === 2 ? 'Aguardando Cliente' :
                                                detalhesprotocolo?.DataSource1?.[0]?.status === 3 ? 'Aguardando Técnico' :
                                                    detalhesprotocolo?.DataSource1?.[0]?.status === 4 ? 'Procedente' :
                                                        detalhesprotocolo?.DataSource1?.[0]?.status === 5 ? 'Improcedente' : ''}
                                </span>
                            </p>
                        </div>
                    </div>

                    {detalhesprotocolo?.DataSource1?.[0]?.status === 2 && (
                        <div className="mensagem-cliente">
                            <h3>Relate ao técnico mais detalhes</h3>
                            <textarea
                                value={mensagem}
                                onChange={handleTextareaChange}
                                placeholder="Envie mais detalhes sobre seu problema..."
                                className={isErro ? 'erro' : ''}
                            />
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }} className="text-left text-gray-500">
                                {mensagem.length}/3500
                            </div>
                            <Segment>
                                <Form.Field>
                                    <h3>Envie mais evidências do seu problema...</h3>
                                    <label>
                                        <i style={{ color: 'lightgray', fontStyle: 'italic' }}>         *Não obrigatório</i>
                                    </label>
                                    <DropzoneComponent
                                        maxFiles={5}
                                        maxSize={5 * 1024 * 1024} // 5MB
                                        accept={{
                                            'image/png': ['.png'],
                                            'image/jpeg': ['.jpeg'],
                                            'image/jpg': ['.jpg'],
                                            'application/pdf': ['.pdf'],
                                            'video/mp4': ['.mp4'],
                                            'video/quicktime': ['.mov'],
                                            // Adicione outros formatos conforme necessário
                                        }}
                                        onFilesChange={(files) => {
                                            setUploadedFiles(files);
                                        }}
                                    />
                                </Form.Field>
                            </Segment>
                            <h3>Arquivos já enviados</h3>
                            <div className="arquivos-enviados">

                                <div className="arquivo-item">
                                    {detalhesprotocolo.DataSource2.map((item) => (
                                        <li style={{ listStyleType: 'none', paddingLeft: 0 }} key={item.id}>
                                            <a href={item.arquivo} className="nome-arquivo" target='_blank' rel='noopener noreferrer'>
                                                {item.nomearq}
                                            </a>
                                        </li>
                                    ))}
                                </div>
                            </div>


                            {
                                loadingResposta ? (
                                    <button className="handle-send loading-spinner-detalhes-resposta" />
                                ) : (
                                    <button className="handle-send" onClick={handleEnviarClick}>Enviar</button>
                                )}


                        </div>
                    )}
                    {Array.isArray(detalhesprotocolo?.DataSource2) &&
                        detalhesprotocolo.DataSource2.map((foto, index) => {
                            if (foto.nomearq === 'Laudo-Tecnico') {
                                return (
                                    <>
                                        <h2>Laudo técnico de conclusão</h2>
                                        <div className="image-container" key={index}>
                                            <a href={foto.arquivo} target="_blank" rel="noopener noreferrer" className="icon-container">
                                                <Icon name="file pdf outline" size="huge" color="red" />
                                            </a>
                                            <p>{foto.nomearq}</p>
                                        </div>
                                    </>
                                );
                            }
                            return null;
                        })
                    }

                    <div className='info-grid-historico'>
                        <div className="info-section">
                            <div className="info-card">
                                <h2>Histórico de Conversa:</h2>
                                <ul>
                                    <li dangerouslySetInnerHTML={{ __html: detalhesprotocolo?.DataSource1?.[0]?.historico }}></li>
                                </ul>
                            </div>
                        </div>
                        <div className="info-section-status">
                            <div className="info-card-status">
                                <h2>Histórico de Status:</h2>
                                <ul>
                                    <li dangerouslySetInnerHTML={{ __html: detalhesprotocolo?.DataSource1?.[0]?.historico_status }}></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <button onClick={handleBackClick} className="back-button">
                        Voltar para Acompanhamento
                    </button>
                </div >
                <Footer />
            </BrowserView>


            <MobileView>
                <div id="topo" />
                <NavBar mobile />

                <div className="detalhes-protocolo-container">
                    <h1>Detalhes do Protocolo</h1>
                    <div className="info-grid">
                        <div className="info-card">
                            <h2>Número do Protocolo:</h2>
                            <p>{numeroProtocolo}</p>
                        </div>
                        <div className="info-card">
                            <h2>Nome do Produto:</h2>
                            <p>{detalhesprotocolo?.DataSource1?.[0]?.produto}</p>
                        </div>
                        <div className="info-card">
                            <h2>Número da Nota Fiscal:</h2>
                            <p>{detalhesprotocolo?.DataSource1?.[0]?.numnota}</p>
                        </div>
                        <div className="info-card">
                            <h2>Data de Abertura:</h2>
                            <p>
                                {new Date(detalhesprotocolo?.DataSource1?.[0]?.dtcriacao).toLocaleDateString('pt-BR', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                })}
                            </p>
                        </div>
                        <div className="info-card">
                            <h2>Status:</h2>
                            <p>
                                <span className={`status-label ${getStatusClass(detalhesprotocolo?.DataSource1?.[0]?.status)}`}>
                                    {detalhesprotocolo?.DataSource1?.[0]?.status === 0 ? 'Aguardando Análise' :
                                        detalhesprotocolo?.DataSource1?.[0]?.status === 1 ? 'Em análise' :
                                            detalhesprotocolo?.DataSource1?.[0]?.status === 2 ? 'Aguardando Cliente' :
                                                detalhesprotocolo?.DataSource1?.[0]?.status === 3 ? 'Aguardando Técnico' :
                                                    detalhesprotocolo?.DataSource1?.[0]?.status === 4 ? 'Procedente' :
                                                        detalhesprotocolo?.DataSource1?.[0]?.status === 5 ? 'Improcedente' : ''}
                                </span>
                            </p>
                        </div>
                    </div>

                    {detalhesprotocolo?.DataSource1?.[0]?.status === 2 && (
                        <div className="mensagem-cliente">
                            <h3>Relate ao técnico mais detalhes</h3>
                            <textarea
                                value={mensagem}
                                onChange={handleTextareaChange}
                                placeholder="Envie mais detalhes sobre seu problema..."
                                className={isErro ? 'erro' : ''}
                            />
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }} className="text-left text-gray-500">
                                {mensagem.length}/3500
                            </div>
                            <Segment>
                                <Form.Field>
                                    <h3>Envie mais evidências do seu problema...</h3>
                                    <label>
                                        <i style={{ color: 'lightgray', fontStyle: 'italic' }}>*Não obrigatório</i>
                                    </label>
                                    <DropzoneComponent
                                        maxFiles={5}
                                        maxSize={5 * 1024 * 1024} // 5MB
                                        accept={{
                                            'image/png': ['.png'],
                                            'image/jpeg': ['.jpeg'],
                                            'image/jpg': ['.jpg'],
                                            'application/pdf': ['.pdf'],
                                            'video/mp4': ['.mp4'],
                                            'video/quicktime': ['.mov'],
                                            // Adicione outros formatos conforme necessário
                                        }}
                                        onFilesChange={(files) => {
                                            setUploadedFiles(files);
                                        }}
                                    />
                                </Form.Field>
                            </Segment>
                            <h3>Arquivos já enviados</h3>
                            <div className="arquivos-enviados">

                                <div className="arquivo-item">
                                    {detalhesprotocolo.DataSource2.map((item) => (
                                        <li style={{ listStyleType: 'none', paddingLeft: 0 }} key={item.id}>
                                            <a href={item.arquivo} className="nome-arquivo" target='_blank' rel='noopener noreferrer'>
                                                {item.nomearq}
                                            </a>
                                        </li>
                                    ))}
                                </div>
                            </div>


                            {
                                loadingResposta ? (
                                    <button className="handle-send-mobile loading-spinner-detalhes-resposta-mobile" />
                                ) : (
                                    <button className="handle-send-mobile " onClick={handleEnviarClick}>Enviar</button>
                                )}


                        </div>
                    )}
                    {Array.isArray(detalhesprotocolo?.DataSource2) &&
                        detalhesprotocolo.DataSource2.map((foto, index) => {
                            if (foto.nomearq === 'Laudo-Tecnico') {
                                return (
                                    <>
                                        <h2>Laudo técnico de conclusão</h2>
                                        <div className="image-container" key={index}>
                                            <a href={foto.arquivo} target="_blank" rel="noopener noreferrer" className="icon-container">
                                                <Icon name="file pdf outline" size="huge" color="red" />
                                            </a>
                                            <p>{foto.nomearq}</p>
                                        </div>
                                    </>
                                );
                            }
                            return null;
                        })
                    }
                    <div className=''>
                        <div className="info-section-mobile">
                            <div className="info-card">
                                <h2>Histórico de Conversa:</h2>
                                <ul>
                                    <li dangerouslySetInnerHTML={{ __html: detalhesprotocolo?.DataSource1?.[0]?.historico }}></li>
                                </ul>
                            </div>
                        </div>
                        <div className="info-section-status">
                            <div className="info-card-status">
                                <h2>Histórico de Status:</h2>
                                <ul>
                                    <li dangerouslySetInnerHTML={{ __html: detalhesprotocolo?.DataSource1?.[0]?.historico_status }}></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <button onClick={handleBackClick} className="back-button">
                        Voltar para Acompanhamento
                    </button>
                </div >

                <Footer mobile />
            </MobileView>

        </>
    );
}

const mapStateToProps = state => ({
    detalhesprotocolo: state.AcompanhamentoReducer.detalhesprotocolo,
    loadingResposta: state.AcompanhamentoReducer.loadingResposta,
    itens_nota: state.ItensNotaReducer.itens_nota
});

export default connect(mapStateToProps, {
    RespostaClienteAction,
    DetalhesProtocoloAction,
    DetalhesProtocoloClearAction
})(DetalhesProtocolo);
