import React from 'react';
import { NumericFormat } from 'react-number-format';
import { Button, Form, FormField, Icon, Input, Loader, Modal, ModalActions, ModalContent, ModalHeader } from 'semantic-ui-react';

function ModalLogin(props) {

    return (
        <Modal size={'mini'} onClose={props.onClosePopup} open={props.openModal}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 10px 0px', marginBottom: -10 }}>
                <button style={{ border: 'none', backgroundColor: 'white', cursor: 'pointer' }} onClick={props.onClosePopup}>
                    <Icon color='grey' name='close' />
                </button>
            </div>
            <ModalHeader>
                Solicite uma assistência
            </ModalHeader>
            <ModalContent>
                {props.isProtoloco === false ? (
                    <div className='container-form-modal'>
                        <p>Entre com seu CPF/CNPJ e Número da Nota:</p>
                        <Form>
                            <FormField
                                error={props.isErrorCpf === true && true}
                            >
                                <NumericFormat
                                    style={{ fontSize: '16px' }}
                                    name='input_cpf'
                                    customInput={Input}
                                    control='input'
                                    removenumericformat="true"
                                    placeholder='CPF/CNPJ.'
                                    allowLeadingZeros={true}
                                    onChange={(e) => props.handleInputChange('cpf', e.target.value)}
                                />
                                {props.isErrorCpf === true && (props.modalData.cpf.length > 0 ? <label>Digite um CPF ou CNPJ válido.</label>
                                    : <label>Digite o CPF/CNPJ.</label>
                                )}
                            </FormField>
                        </Form>

                        <Form>

                            <FormField
                                error={props.isErrorNota === true && true}
                            >
                                <NumericFormat
                                    style={{ marginTop: 10, fontSize: '16px' }}
                                    name='input_nota'
                                    customInput={Input}
                                    control='input'
                                    removenumericformat="true"
                                    placeholder='Nº da Nota'
                                    allowLeadingZeros={true}
                                    onChange={(e) => props.handleInputChange('nota', e.target.value)}
                                />
                                {props.isErrorNota && <label>Digite o número da nota.</label>}

                            </FormField>

                        </Form>
                        {props.isErrorNotFound && <label style={{ marginTop: 20, color: 'red', fontWeight: 'bold' }}>{props.isErrorNotFound[0].message}</label>}
                        <p style={{ marginTop: 20, textAlign: 'center' }}>Já possui uma solicitação em aberto?</p>

                        <Button color='grey' size='mini' onClick={props.handleIsProtocolo}>
                            Clique aqui
                        </Button>

                    </div>
                ) : (
                    <div className='container-form-modal'>
                        <p>Entre com seu número de protocolo:</p>
                        <Form>

                            <FormField
                                error={props.isErrorProtocolo === true && true}
                            >
                                <Input placeholder='Nº de protocolo' onChange={(e) => props.handleInputChange('protocolo', e.target.value)} />
                                {props.isErrorProtocolo && <label>Digite o número do protocolo.</label>}

                            </FormField>

                        </Form>
                        {props.isErrorNotFound && <label style={{ marginTop: 20, color: 'red', fontWeight: 'bold' }}>{props.isErrorNotFound[0].message}</label>}

                        <div style={{ marginTop: 15 }}>
                            <Button color='grey' size='mini' onClick={props.handleIsProtocolo}>
                                Voltar
                            </Button>
                        </div>
                    </div>
                )}
            </ModalContent>
            <ModalActions>
                <Button color='green' onClick={props.handleModalSubmit}>
                    {props.loading === true ? (
                        <div style={{ width: '65px' }}>
                            <Loader active inline size='tiny' />
                        </div>
                    ) : (
                        <div> Confirmar</div>
                    )}
                </Button>
            </ModalActions>
        </Modal>
    );

}

export default ModalLogin;