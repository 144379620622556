import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Grid, GridRow, GridColumn, Button, Icon, Feed, FeedEvent } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { BrowserView, MobileView } from 'react-device-detect';

const DropzoneComponent = ({
    maxFiles = 5,
    maxSize = 5 * 1024 * 1024, // 5MB por padrão, exceto para MP4
    accept = {
        'application/pdf': ['.pdf'],
        'image/png': ['.png'],
        'image/jpeg': ['.jpg', '.jpeg'],
        'video/mp4': ['.mp4'], // Adicionando suporte para arquivos MP4
        'application/msword': ['.doc'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        'application/vnd.ms-outlook': ['.msg', '.eml'],
        'video/quicktime': ['.mov'],
    },
    onFilesChange
}) => {
    const [files, setFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const { getRootProps, getInputProps } = useDropzone({
        accept,
        onDrop: (acceptedFiles, fileRejections) => {
            let newFiles = [...files];
            let newErrorMessage = '';

            acceptedFiles.forEach(file => {
                const isMP4 = file.type === 'video/mp4';
                const fileSizeLimit = isMP4 ? 10 * 1024 * 1024 : maxSize;

                if (file.size > fileSizeLimit) {
                    newErrorMessage = `O arquivo "${file.name}" é maior que ${fileSizeLimit / 1024 / 1024}MB.`;
                } else if (newFiles.length >= maxFiles) {
                    newErrorMessage = `Você só pode fazer upload de até ${maxFiles} arquivos.`;
                } else if (newFiles.some(f => f.name === file.name)) {
                    newErrorMessage = `O arquivo "${file.name}" já foi adicionado.`;
                } else {
                    newFiles.push({
                        file: file,
                        name: file.name,
                        size: file.size,
                        type: file.type
                    });
                }
            });

            fileRejections.forEach(rejection => {
                if (rejection.errors.some(e => e.code === 'file-too-large')) {
                    newErrorMessage = `O arquivo "${rejection.file.name}" é maior que ${maxSize / 1024 / 1024}MB.`;
                }
                if (rejection.errors.some(e => e.code === 'file-invalid-type')) {
                    console.log(rejection)
                    newErrorMessage = `O tipo de arquivo "${rejection.file.name}" não é suportado.`;
                }
            });

            if (newErrorMessage) {
                setErrorMessage(newErrorMessage);
            } else {
                setFiles(newFiles);
                setErrorMessage('');
                onFilesChange(newFiles);
            }
        }
    });

    const removeFile = fileName => {
        const updatedFiles = files.filter(file => file.name !== fileName);
        setFiles(updatedFiles);
        onFilesChange(updatedFiles);
    };

    return (
        <div>

            <BrowserView>
                <Grid columns={2} divided>
                    <GridRow>
                        <GridColumn>
                            <div {...getRootProps()} style={{ border: '2px dashed #007BFF', padding: '20px', cursor: 'pointer' }}>
                                <input {...getInputProps()} />
                                <Feed>
                                    <FeedEvent
                                        icon='cloud upload'
                                        date='Arraste e solte seus arquivos aqui, ou clique para enviar arquivos.'
                                        summary={`Apenas ${Object.keys(accept).join(', ')}. Máximo de ${maxFiles} arquivos e ${maxSize / 1024 / 1024}MB por arquivo.`}
                                    />
                                </Feed>
                            </div>
                        </GridColumn>
                        <div>
                            <GridColumn>
                                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                                <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                                    {files.map(file => (
                                        <li key={file.name}>
                                            <div style={{ marginBottom: '2px' }}>
                                                <Button icon color='red' size='mini' onClick={() => removeFile(file.name)}>
                                                    <Icon name='trash alternate outline' />
                                                </Button>
                                                {file.name} - {(file.size / 1024 / 1024).toFixed(2)} MB - {file.type}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </GridColumn>
                        </div>

                    </GridRow>
                </Grid>
            </BrowserView>


            <MobileView>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    <div {...getRootProps()} style={{ border: '2px dashed #007BFF', padding: '20px', cursor: 'pointer' }}>
                        <input {...getInputProps()} />
                        <Feed>
                            <FeedEvent
                                icon='cloud upload'
                                date='Arraste e solte seus arquivos aqui, ou clique para enviar arquivos.'
                                summary={`Apenas ${Object.keys(accept).join(', ')}. Máximo de ${maxFiles} arquivos e ${maxSize / 1024 / 1024}MB por arquivo.`}
                            />
                        </Feed>
                    </div>

                    <div>
                        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                        <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                            {files.map(file => (
                                <li key={file.name}>
                                    <div style={{ marginBottom: '2px' }}>
                                        <Button icon color='red' size='mini' onClick={() => removeFile(file.name)}>
                                            <Icon name='trash alternate outline' />
                                        </Button>
                                        {file.name} - {(file.size / 1024 / 1024).toFixed(2)} MB - {file.type}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </MobileView>



        </div>
    );
};

DropzoneComponent.propTypes = {
    maxFiles: PropTypes.number,
    maxSize: PropTypes.number,
    accept: PropTypes.object,
    onFilesChange: PropTypes.func.isRequired
};

export default DropzoneComponent;
