
function CardEspacoFix() {
    return (
        <div className="card-container">
            <div className='text-block'>
                <h1 className='piso-name'>Acessório para Instalação de Drywall</h1>
                <div className='garantia'>
                    <span>Período de Garantia Residencial</span>
                    <div className='garantia-info'>
                        <h1 className='garantia-numero'>3</h1>
                        <span className='garantia-meses'>meses</span>
                    </div>
                </div>
                <hr />
                <div className='garantia'>
                    <span>Período de Garantia Comercial</span>
                    <div className='garantia-info'>
                        <h1 className='garantia-numero'>3</h1>
                        <span className='garantia-meses'>meses</span>
                    </div>
                </div>
            </div>


            <div className='text-block'>
                <h1 className='piso-name'>Acessório para Marcenaria</h1>
                <div className='garantia'>
                    <span>Período de Garantia Residencial</span>
                    <div className='garantia-info'>
                        <h1 className='garantia-numero'>3</h1>
                        <span className='garantia-meses'>meses</span>
                    </div>
                </div>
                <hr />
                <div className='garantia'>
                    <span>Período de Garantia Comercial</span>
                    <div className='garantia-info'>
                        <h1 className='garantia-numero'>3</h1>
                        <span className='garantia-meses'>meses</span>
                    </div>
                </div>
            </div>

            <div className='text-block'>
                <h1 className='piso-name'>Escada</h1>
                <div className='garantia'>
                    <span>Período de Garantia Residencial</span>
                    <div className='garantia-info'>
                        <h1 className='garantia-numero'>3</h1>
                        <span className='garantia-meses'>meses</span>
                    </div>
                </div>
                <hr />
                <div className='garantia'>
                    <span>Período de Garantia Comercial</span>
                    <div className='garantia-info'>
                        <h1 className='garantia-numero'>3</h1>
                        <span className='garantia-meses'>meses</span>
                    </div>
                </div>
            </div>

            <div className='text-block'>
                <h1 className='piso-name'>Fechadura</h1>
                <div className='garantia'>
                    <span>Período de Garantia Residencial</span>
                    <div className='garantia-info'>
                        <h1 className='garantia-numero'>3</h1>
                        <span className='garantia-meses'>meses</span>
                    </div>
                </div>
                <hr />
                <div className='garantia'>
                    <span>Período de Garantia Comercial</span>
                    <div className='garantia-info'>
                        <h1 className='garantia-numero'>3</h1>
                        <span className='garantia-meses'>meses</span>
                    </div>
                </div>
            </div>


            <div className='text-block'>
                <h1 className='piso-name'>Ferramenta</h1>
                <div className='garantia'>
                    <span>Período de Garantia Residencial</span>
                    <div className='garantia-info'>
                        <h1 className='garantia-numero'>3</h1>
                        <span className='garantia-meses'>meses</span>
                    </div>
                </div>
                <hr />
                <div className='garantia'>
                    <span>Período de Garantia Comercial</span>
                    <div className='garantia-info'>
                        <h1 className='garantia-numero'>3</h1>
                        <span className='garantia-meses'>meses</span>
                    </div>
                </div>
            </div>

            <div className='text-block'>
                <h1 className='piso-name'>Manta Lã De Vidro</h1>
                <div className='garantia'>
                    <span>Período de Garantia Residencial</span>
                    <div className='garantia-info'>
                        <h1 className='garantia-numero'>3</h1>
                        <span className='garantia-meses'>meses</span>
                    </div>
                </div>
                <hr />
                <div className='garantia'>
                    <span>Período de Garantia Comercial</span>
                    <div className='garantia-info'>
                        <h1 className='garantia-numero'>3</h1>
                        <span className='garantia-meses'>meses</span>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default CardEspacoFix;