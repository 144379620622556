import { useState } from 'react';
import Footer from "../../../Footer/Footer";
import NavBar from "../../../Navbar/NavBar";
import { BrowserView, MobileView } from 'react-device-detect';
import { connect } from 'react-redux';
import ModalLoading from './ModalLoading';
import { FormEnviaDadosPisoAction } from '../../../../actions/FormActions';
import { Form, Segment, Button, Grid, TextArea, Loader } from 'semantic-ui-react';
import DropzoneComponent from '../formularios/Dropzone'
import { useNavigate } from 'react-router';


function FormPiso(props) {
    const { FormEnviaDadosPisoAction } = props;
    const { form_info, loading_form_dados } = props;
    const navigate = useNavigate();


    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [fileError, setFileError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);


    let telefone = form_info?.telefone?.replace(/\s+/g, '');
    let fax = form_info?.fax?.replace(/\s+/g, '');
    const nomeConsumidor = form_info.nomeparc;
    const enderecoConsumidor = form_info.endereco;
    const bairroConsumidor = form_info.bairro;
    const cidadeConsumidor = form_info.cidade;
    const foneConsumidor = telefone?.length === 0 ? (fax?.length === 0 ? '' : fax) : telefone;
    const emailConsumidor = form_info.email;
    const cpfConsumidor = form_info.cgc_cpf;
    const nomeprod = form_info.desc_prod;


    // States para armazenar os valores do formulário
    const [formData, setFormData] = useState({
        localPiso: '',
        temUmidade: '',
        preparacaoContraPiso: '',
        recortesNorma: '',
        moveisSobrePiso: '',
        perfisDilatacaoAmbientes: '',
        perfisDilatacao8mts: '',
        manutencaoPiso: '',
        nome: '',
        endereco: '',
        bairro: '',
        cidade: '',
        fone: '',
        email: '',
        cpf: '',
        dataInstalacao: '',
        observacoes: '',
    });
    const [isChecked, setIsChecked] = useState(false);
    // State para controlar os erros
    const [formErrors, setFormErrors] = useState({});

    // Função para lidar com mudanças nos campos do formulário
    const handleChange = (e, { name, value }) => {
        setFormData({ ...formData, [name]: value });
        setFormErrors({ ...formErrors, [name]: false }); // Remove o erro ao corrigir
        console.log(`Campo ${name} alterado para: ${value}`); // Log do campo alterado
    };

    const errors = {};

    // Função para validação
    const validateForm = () => {

        // Verificação para campos obrigatórios de Radio
        if (!formData.localPiso) errors.localPiso = true;
        if (!formData.temUmidade) errors.temUmidade = true;
        if (!formData.preparacaoContraPiso) errors.preparacaoContraPiso = true;
        if (!formData.recortesNorma) errors.recortesNorma = true;
        if (!formData.moveisSobrePiso) errors.moveisSobrePiso = true;
        if (!formData.perfisDilatacaoAmbientes) errors.perfisDilatacaoAmbientes = true;
        if (!formData.perfisDilatacao8mts) errors.perfisDilatacao8mts = true;
        if (!formData.manutencaoPiso) errors.manutencaoPiso = true;


        setFormErrors(errors); // Definir os erros
        return Object.keys(errors).length === 0; // Retorna true se não houver erros
    };

    const handleSubmit = () => {
        validateForm()
        // Se já estiver submetendo, não faça nada
        if (isSubmitting) {
            return;
        }
        const errors = {};

        // Verifica se os campos estão vazios e seta o estado de erro
        Object.keys(formData).forEach((field) => {
            if (!formData[field]) {
                errors[field] = true;
            }
        });

        // Se houver erros, define os erros e não submete o formulário
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors); // Ativa o estado de erro
        } else {
            // Caso não tenha erros, envie o formulário
            if (uploadedFiles.length === 0) {
                setFileError('Por favor, adicione ao menos um arquivo.');
            } else {
                setFileError('');
                setIsSubmitting(true);
                FormEnviaDadosPisoAction(form_info, formData, uploadedFiles).then(() => {
                    setIsChecked(true);
                    setTimeout(() => {
                        navigate('/assistencia/protocolo');
                        setIsSubmitting(false);
                    }, 4000);
                })
                console.log('Form enviado com sucesso!', formData, uploadedFiles);
            }

        }
    };




    const handleChangeNumber = (e) => {
        const { name, value } = e.target;
        // Verifica se o valor é apenas numérico antes de atualizar o estado
        if (/^\d*$/.test(value)) { // Permite apenas números
            setFormData(prevData => ({ ...prevData, [name]: value }));
        }
    };
    return (
        <>
            <BrowserView>
                <div id="topo" />
                <NavBar />
                <Form onSubmit={handleSubmit}>
                    <Segment>
                        <h2>Assistência EspaçoFloor</h2>
                        <hr></hr>
                        <p style={{ fontSize: '24px' }}>{nomeprod}</p>
                        <h3>Dados do Cliente:</h3>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>Nome</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Nome'
                                    name='nome'
                                    label={nomeConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Telefone</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Telefone'
                                    name='telefone'
                                    label={foneConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Email</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Email'
                                    name='email'
                                    label={emailConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>CPF</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='CPF'
                                    name='cpf'
                                    label={cpfConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>Endereço</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Endereço'
                                    name='endereco'
                                    label={enderecoConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Bairro</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Bairro'
                                    name='bairro'
                                    label={bairroConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Cidade</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Cidade'
                                    name='cidade'
                                    label={cidadeConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                        </Form.Group>
                        <h3>Dados do instalador:</h3>
                        <Form.Group widths='equal'>
                            <Form.Input
                                label='Nome do instalador'
                                name='nome'
                                value={formData.nome}
                                onChange={handleChange}
                                error={formErrors.nome}
                                maxLength={100}
                            />
                            <Form.Input
                                label='Endereço'
                                name='endereco'
                                value={formData.endereco}
                                onChange={handleChange}
                                error={formErrors.endereco}
                                maxLength={100}
                            />
                            <Form.Input
                                label='Bairro'
                                name='bairro'
                                value={formData.bairro}
                                onChange={handleChange}
                                error={formErrors.bairro}
                                maxLength={100}
                            />
                            <Form.Input
                                label='Cidade'
                                name='cidade'
                                value={formData.cidade}
                                onChange={handleChange}
                                error={formErrors.cidade}
                                maxLength={50}
                            />
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Input
                                label='CPF'
                                name='cpf'
                                value={formData.cpf}
                                onChange={handleChange}
                                error={formErrors.cpf}
                                maxLength={14}
                                type="text"
                                pattern="[0-9]*"
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                autoComplete="off"
                            />
                            <Form.Input
                                label='Telefone'
                                name='fone'
                                autoComplete="off"
                                value={formData.fone}
                                onChange={handleChange}
                                error={formErrors.fone}
                                type='text' // Define como campo de telefone
                                maxLength={12}
                                pattern="[0-9]*"
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}

                            />
                            <Form.Input
                                label='Email'
                                name='email'
                                value={formData.email}
                                onChange={handleChange}
                                error={formErrors.email}
                                type='email'
                                maxLength={70}
                            />
                            <Form.Input
                                label='Data da Instalação'
                                name='dataInstalacao'
                                value={formData.dataInstalacao}
                                onChange={handleChange}
                                error={formErrors.dataInstalacao}
                                type='date'
                            />
                        </Form.Group>
                        <h3>Questionário (Consultar Instalador)</h3>
                        <Grid style={{ overflow: 'hidden' }} >
                            <Grid.Column width={5} style={{ overflow: 'hidden' }} >
                                <Form.Group grouped style={{ overflow: 'hidden' }} >
                                    <label>1. Local em que o piso está instalado</label>
                                    <Form.Radio
                                        label='Pavimento Térreo'
                                        name='localPiso'
                                        value='PAVIMENTO TÉRREO'
                                        checked={formData.localPiso === 'PAVIMENTO TÉRREO'}
                                        onChange={handleChange}
                                        error={formErrors.localPiso}
                                    />
                                    <Form.Radio
                                        label='Sobre piso de madeira'
                                        name='localPiso'
                                        value='SOBRE PISO DE MADEIRA'
                                        checked={formData.localPiso === 'SOBRE PISO DE MADEIRA'}
                                        onChange={handleChange}
                                        error={formErrors.localPiso}
                                    />
                                    <Form.Radio
                                        label='Sobre cerâmico'
                                        name='localPiso'
                                        value='SOBRE CERÂMICO'
                                        checked={formData.localPiso === 'SOBRE CERÂMICO'}
                                        onChange={handleChange}
                                        error={formErrors.localPiso}
                                    />
                                    <Form.Radio
                                        label='Sobre cimento'
                                        name='localPiso'
                                        value='SOBRE CIMENTO'
                                        checked={formData.localPiso === 'SOBRE CIMENTO'}
                                        onChange={handleChange}
                                        error={formErrors.localPiso}
                                    />
                                    <Form.Radio
                                        label='Não sei'
                                        name='localPiso'
                                        value='Não sei'
                                        checked={formData.localPiso === 'Não sei'}
                                        onChange={handleChange}
                                        error={formErrors.localPiso}
                                    />
                                </Form.Group>
                            </Grid.Column>

                            <Grid.Column width={5}>
                                <Form.Group grouped>
                                    <label>2. Tem umidade no local?</label>
                                    <Form.Radio
                                        label='Sim'
                                        name='temUmidade'
                                        value='SIM'
                                        checked={formData.temUmidade === 'SIM'}
                                        onChange={handleChange}
                                        error={formErrors.temUmidade}
                                    />
                                    <Form.Radio
                                        label='Não'
                                        name='temUmidade'
                                        value='NÃO'
                                        checked={formData.temUmidade === 'NÃO'}
                                        onChange={handleChange}
                                        error={formErrors.temUmidade}
                                    />
                                    <Form.Radio
                                        label='Não sei'
                                        name='temUmidade'
                                        value='Não sei'
                                        checked={formData.temUmidade === 'Não sei'}
                                        onChange={handleChange}
                                        error={formErrors.temUmidade}
                                    />
                                </Form.Group>
                            </Grid.Column>

                            <Grid.Column width={5}>
                                <Form.Group grouped>
                                    <label>3. Preparação do contra-piso</label>
                                    <Form.Radio
                                        label='Argamassa de preparo'
                                        name='preparacaoContraPiso'
                                        value='ARGAMASSA DE PREPARO'
                                        checked={formData.preparacaoContraPiso === 'ARGAMASSA DE PREPARO'}
                                        onChange={handleChange}
                                        error={formErrors.preparacaoContraPiso}
                                    />
                                    <Form.Radio
                                        label='PVA (cimento e cola)'
                                        name='preparacaoContraPiso'
                                        value='PVA (CIMENTO e COLA)'
                                        checked={formData.preparacaoContraPiso === 'PVA (CIMENTO e COLA)'}
                                        onChange={handleChange}
                                        error={formErrors.preparacaoContraPiso}
                                    />
                                    <Form.Radio
                                        label='Cola utilizada (para pisos colado)'
                                        name='preparacaoContraPiso'
                                        value='COLA UTILIZADA (PARA PISOS COLADO)'
                                        checked={formData.preparacaoContraPiso === 'COLA UTILIZADA (PARA PISOS COLADO)'}
                                        onChange={handleChange}
                                        error={formErrors.preparacaoContraPiso}
                                    />
                                    <Form.Radio
                                        label='Não sei'
                                        name='preparacaoContraPiso'
                                        value='Não sei'
                                        checked={formData.preparacaoContraPiso === 'Não sei'}
                                        onChange={handleChange}
                                        error={formErrors.preparacaoContraPiso}
                                    />
                                </Form.Group>
                            </Grid.Column>



                            <Grid.Column width={5}>
                                <Form.Group grouped>
                                    <label>4. Os recortes/amarração estão de acordo com as normas? (para pisos clicados)</label>
                                    <Form.Radio
                                        label='Sim'
                                        name='recortesNorma'
                                        value='SIM'
                                        checked={formData.recortesNorma === 'SIM'}
                                        onChange={handleChange}
                                        error={formErrors.recortesNorma}
                                    />
                                    <Form.Radio
                                        label='Não'
                                        name='recortesNorma'
                                        value='NÃO'
                                        checked={formData.recortesNorma === 'NÃO'}
                                        onChange={handleChange}
                                        error={formErrors.recortesNorma}
                                    />
                                    <Form.Radio
                                        label='Não sei'
                                        name='recortesNorma'
                                        value='Não sei'
                                        checked={formData.recortesNorma === 'Não sei'}
                                        onChange={handleChange}
                                        error={formErrors.recortesNorma}
                                    />
                                </Form.Group>
                            </Grid.Column>

                            <Grid.Column width={5}>
                                <Form.Group grouped>
                                    <label>5. Foram instalados móveis planejados sobre o piso?</label>
                                    <Form.Radio
                                        label='Sim'
                                        name='moveisSobrePiso'
                                        value='SIM'
                                        checked={formData.moveisSobrePiso === 'SIM'}
                                        onChange={handleChange}
                                        error={formErrors.moveisSobrePiso}
                                    />
                                    <Form.Radio
                                        label='Não'
                                        name='moveisSobrePiso'
                                        value='NÃO'
                                        checked={formData.moveisSobrePiso === 'NÃO'}
                                        onChange={handleChange}
                                        error={formErrors.moveisSobrePiso}
                                    />
                                    <Form.Radio
                                        label='Não sei'
                                        name='moveisSobrePiso'
                                        value='Não sei'
                                        checked={formData.moveisSobrePiso === 'Não sei'}
                                        onChange={handleChange}
                                        error={formErrors.moveisSobrePiso}
                                    />
                                </Form.Group>
                            </Grid.Column>

                            <Grid.Column width={5}>
                                <Form.Group grouped>
                                    <label>6. Foram instalados perfis de dilatação/transição entre ambientes? (para pisos laminados)</label>
                                    <Form.Radio
                                        label='Sim'
                                        name='perfisDilatacaoAmbientes'
                                        value='SIM'
                                        checked={formData.perfisDilatacaoAmbientes === 'SIM'}
                                        onChange={handleChange}
                                        error={formErrors.perfisDilatacaoAmbientes}
                                    />
                                    <Form.Radio
                                        label='Não'
                                        name='perfisDilatacaoAmbientes'
                                        value='NÃO'
                                        checked={formData.perfisDilatacaoAmbientes === 'NÃO'}
                                        onChange={handleChange}
                                        error={formErrors.perfisDilatacaoAmbientes}
                                    />
                                    <Form.Radio
                                        label='Não sei'
                                        name='perfisDilatacaoAmbientes'
                                        value='Não sei'
                                        checked={formData.perfisDilatacaoAmbientes === 'Não sei'}
                                        onChange={handleChange}
                                        error={formErrors.perfisDilatacaoAmbientes}
                                    />
                                </Form.Group>
                            </Grid.Column>



                            <Grid.Column width={5}>
                                <Form.Group grouped>
                                    <label>7. Foram instalados perfis de dilatação/transição para área acima de 8mts? (para pisos laminados)</label>
                                    <Form.Radio
                                        label='Sim'
                                        name='perfisDilatacao8mts'
                                        value='SIM'
                                        checked={formData.perfisDilatacao8mts === 'SIM'}
                                        onChange={handleChange}
                                        error={formErrors.perfisDilatacao8mts}
                                    />
                                    <Form.Radio
                                        label='Não'
                                        name='perfisDilatacao8mts'
                                        value='NÃO'
                                        checked={formData.perfisDilatacao8mts === 'NÃO'}
                                        onChange={handleChange}
                                        error={formErrors.perfisDilatacao8mts}
                                    />
                                    <Form.Radio
                                        label='Não sei'
                                        name='perfisDilatacao8mts'
                                        value='Não sei'
                                        checked={formData.perfisDilatacao8mts === 'Não sei'}
                                        onChange={handleChange}
                                        error={formErrors.perfisDilatacao8mts}
                                    />
                                </Form.Group>
                            </Grid.Column>

                            <Grid.Column width={10}>
                                <Form.Group grouped>
                                    <label>8. Como é feita a manutenção (limpeza) do piso?</label>
                                    <Form.Input
                                        name='manutencaoPiso'
                                        value={formData.manutencaoPiso}
                                        onChange={handleChange}
                                        placeholder='Descreva o método de limpeza do piso'
                                        error={formErrors.manutencaoPiso}
                                        maxLength={240}
                                    />
                                </Form.Group>
                            </Grid.Column>


                        </Grid>
                        <Form.Group widths='equal'>
                            <Form.Field error={formErrors.observacoes}>
                                <label>Descreva com detalhes o motivo da solicitação</label>
                                <TextArea
                                    placeholder='Descreva com detalhes o motivo da solicitação'
                                    name='observacoes'
                                    value={formData.observacoes}
                                    onChange={handleChange}
                                    maxLength={3500}
                                    style={{ resize: 'none' }} // Desabilita o redimensionamento
                                />
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }} className="text-left text-gray-500">
                                    {formData.observacoes.length}/3500
                                </div>
                            </Form.Field>
                        </Form.Group>
                        <Form.Field error={!!(errors.uploadedFiles || fileError)}>
                            <label>Upload de Imagem</label>
                            <DropzoneComponent
                                maxFiles={5}
                                maxSize={5 * 1024 * 1024} // 5MB
                                accept={{
                                    'image/png': ['.png'],
                                    'image/jpeg': ['.jpeg'],
                                    'image/jpg': ['.jpg'],
                                    'application/pdf': ['.pdf'],
                                    'video/mp4': ['.mp4'],
                                    'video/quicktime': ['.mov'],
                                    // Adicione outros formatos conforme necessário
                                }}
                                onFilesChange={(files) => {
                                    setUploadedFiles(files);
                                    if (files.length === 0) {
                                        setFileError('Por favor, adicione ao menos um arquivo.');
                                    } else {
                                        setFileError('');
                                    }
                                }}
                            />
                            {fileError && <p style={{ color: 'red' }}>{fileError}</p>}
                        </Form.Field>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Button primary type='submit' style={{ marginBottom: '25px', width: '100%', margin: '5px' }} >
                                {loading_form_dados || isSubmitting ? (
                                    <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                                        <ModalLoading
                                            sending={isSubmitting}
                                            checked={isChecked}
                                        />
                                    </div>
                                ) : (

                                    <div> Confirmar</div>
                                )}

                            </Button>
                        </div>
                    </Segment>
                </Form>
                <Footer />
            </BrowserView>
            <MobileView>
                <div id="topo" />
                <NavBar mobile />
                <Form onSubmit={handleSubmit}>
                    <Segment>
                        <h2>Assistência EspaçoFloor</h2>
                        <hr></hr>
                        <p style={{ fontSize: '24px' }}>{nomeprod}</p>
                        <h3>Dados do Cliente:</h3>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>Nome</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Nome'
                                    name='nome'
                                    label={nomeConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Telefone</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Telefone'
                                    name='telefone'
                                    label={foneConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Email</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Email'
                                    name='email'
                                    label={emailConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>CPF</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='CPF'
                                    name='cpf'
                                    label={cpfConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>Endereço</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Endereço'
                                    name='endereco'
                                    label={enderecoConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Bairro</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Bairro'
                                    name='bairro'
                                    label={bairroConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Cidade</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Cidade'
                                    name='cidade'
                                    label={cidadeConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                        </Form.Group>
                        <h3>Dados do instalador:</h3>
                        <Form.Group widths='equal'>
                            <Form.Input
                                label='Nome do instalador'
                                name='nome'
                                value={formData.nome}
                                onChange={handleChange}
                                error={formErrors.nome}
                                maxLength={100}
                            />
                            <Form.Input
                                label='Endereço'
                                name='endereco'
                                value={formData.endereco}
                                onChange={handleChange}
                                error={formErrors.endereco}
                                maxLength={100}
                            />
                            <Form.Input
                                label='Bairro'
                                name='bairro'
                                value={formData.bairro}
                                onChange={handleChange}
                                error={formErrors.bairro}
                                maxLength={100}
                            />
                            <Form.Input
                                label='Cidade'
                                name='cidade'
                                value={formData.cidade}
                                onChange={handleChange}
                                error={formErrors.cidade}
                                maxLength={50}
                            />
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Input
                                label='CPF'
                                name='cpf'
                                value={formData.cpf}
                                onChange={handleChangeNumber}
                                error={formErrors.cpf}
                                maxLength={14}
                                type="text"
                                pattern="[0-9]*"
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                autoComplete="off"
                            />
                            <Form.Input
                                label='Telefone'
                                name='fone'
                                autoComplete="off"
                                value={formData.fone}
                                onChange={handleChange}
                                error={formErrors.fone}
                                type='text' // Define como campo de telefone
                                maxLength={12}
                                pattern="[0-9]*"
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}

                            />
                            <Form.Input
                                label='Email'
                                name='email'
                                value={formData.email}
                                onChange={handleChange}
                                error={formErrors.email}
                                type='email'
                                maxLength={70}
                            />
                            <Form.Input
                                label='Data da Instalação'
                                name='dataInstalacao'
                                value={formData.dataInstalacao}
                                onChange={handleChange}
                                error={formErrors.dataInstalacao}
                                type='date'
                            />
                        </Form.Group>
                        <h3>Questionário (Consultar Instalador)</h3>


                        <Form.Group grouped style={{ overflow: 'hidden' }} >
                            <label>1. Local em que o piso está instalado</label>
                            <Form.Radio
                                label='Pavimento Térreo'
                                name='localPiso'
                                value='PAVIMENTO TÉRREO'
                                checked={formData.localPiso === 'PAVIMENTO TÉRREO'}
                                onChange={handleChange}
                                error={formErrors.localPiso}
                            />
                            <Form.Radio
                                label='Sobre piso de madeira'
                                name='localPiso'
                                value='SOBRE PISO DE MADEIRA'
                                checked={formData.localPiso === 'SOBRE PISO DE MADEIRA'}
                                onChange={handleChange}
                                error={formErrors.localPiso}
                            />
                            <Form.Radio
                                label='Sobre cerâmico'
                                name='localPiso'
                                value='SOBRE CERÂMICO'
                                checked={formData.localPiso === 'SOBRE CERÂMICO'}
                                onChange={handleChange}
                                error={formErrors.localPiso}
                            />
                            <Form.Radio
                                label='Sobre cimento'
                                name='localPiso'
                                value='SOBRE CIMENTO'
                                checked={formData.localPiso === 'SOBRE CIMENTO'}
                                onChange={handleChange}
                                error={formErrors.localPiso}
                            />
                            <Form.Radio
                                label='Não sei'
                                name='localPiso'
                                value='Não sei'
                                checked={formData.localPiso === 'Não sei'}
                                onChange={handleChange}
                                error={formErrors.localPiso}
                            />
                        </Form.Group>



                        <Form.Group grouped>
                            <label>2. Tem umidade no local?</label>
                            <Form.Radio
                                label='Sim'
                                name='temUmidade'
                                value='SIM'
                                checked={formData.temUmidade === 'SIM'}
                                onChange={handleChange}
                                error={formErrors.temUmidade}
                            />
                            <Form.Radio
                                label='Não'
                                name='temUmidade'
                                value='NÃO'
                                checked={formData.temUmidade === 'NÃO'}
                                onChange={handleChange}
                                error={formErrors.temUmidade}
                            />
                            <Form.Radio
                                label='Não sei'
                                name='temUmidade'
                                value='Não sei'
                                checked={formData.temUmidade === 'Não sei'}
                                onChange={handleChange}
                                error={formErrors.temUmidade}
                            />
                        </Form.Group>


                        <Form.Group grouped>
                            <label>3. Preparação do contra-piso</label>
                            <Form.Radio
                                label='Argamassa de preparo'
                                name='preparacaoContraPiso'
                                value='ARGAMASSA DE PREPARO'
                                checked={formData.preparacaoContraPiso === 'ARGAMASSA DE PREPARO'}
                                onChange={handleChange}
                                error={formErrors.preparacaoContraPiso}
                            />
                            <Form.Radio
                                label='PVA (cimento e cola)'
                                name='preparacaoContraPiso'
                                value='PVA (CIMENTO e COLA)'
                                checked={formData.preparacaoContraPiso === 'PVA (CIMENTO e COLA)'}
                                onChange={handleChange}
                                error={formErrors.preparacaoContraPiso}
                            />
                            <Form.Radio
                                label='Cola utilizada (para pisos colado)'
                                name='preparacaoContraPiso'
                                value='COLA UTILIZADA (PARA PISOS COLADO)'
                                checked={formData.preparacaoContraPiso === 'COLA UTILIZADA (PARA PISOS COLADO)'}
                                onChange={handleChange}
                                error={formErrors.preparacaoContraPiso}
                            />
                            <Form.Radio
                                label='Não sei'
                                name='preparacaoContraPiso'
                                value='Não sei'
                                checked={formData.preparacaoContraPiso === 'Não sei'}
                                onChange={handleChange}
                                error={formErrors.preparacaoContraPiso}
                            />
                        </Form.Group>





                        <Form.Group grouped>
                            <label>4. Os recortes/amarração estão de acordo com as normas? (para pisos clicados)</label>
                            <Form.Radio
                                label='Sim'
                                name='recortesNorma'
                                value='SIM'
                                checked={formData.recortesNorma === 'SIM'}
                                onChange={handleChange}
                                error={formErrors.recortesNorma}
                            />
                            <Form.Radio
                                label='Não'
                                name='recortesNorma'
                                value='NÃO'
                                checked={formData.recortesNorma === 'NÃO'}
                                onChange={handleChange}
                                error={formErrors.recortesNorma}
                            />
                            <Form.Radio
                                label='Não sei'
                                name='recortesNorma'
                                value='Não sei'
                                checked={formData.recortesNorma === 'Não sei'}
                                onChange={handleChange}
                                error={formErrors.recortesNorma}
                            />
                        </Form.Group>



                        <Form.Group grouped>
                            <label>5. Foram instalados móveis planejados sobre o piso?</label>
                            <Form.Radio
                                label='Sim'
                                name='moveisSobrePiso'
                                value='SIM'
                                checked={formData.moveisSobrePiso === 'SIM'}
                                onChange={handleChange}
                                error={formErrors.moveisSobrePiso}
                            />
                            <Form.Radio
                                label='Não'
                                name='moveisSobrePiso'
                                value='NÃO'
                                checked={formData.moveisSobrePiso === 'NÃO'}
                                onChange={handleChange}
                                error={formErrors.moveisSobrePiso}
                            />
                            <Form.Radio
                                label='Não sei'
                                name='moveisSobrePiso'
                                value='Não sei'
                                checked={formData.moveisSobrePiso === 'Não sei'}
                                onChange={handleChange}
                                error={formErrors.moveisSobrePiso}
                            />
                        </Form.Group>



                        <Form.Group grouped>
                            <label>6. Foram instalados perfis de dilatação/transição entre ambientes? (para pisos laminados)</label>
                            <Form.Radio
                                label='Sim'
                                name='perfisDilatacaoAmbientes'
                                value='SIM'
                                checked={formData.perfisDilatacaoAmbientes === 'SIM'}
                                onChange={handleChange}
                                error={formErrors.perfisDilatacaoAmbientes}
                            />
                            <Form.Radio
                                label='Não'
                                name='perfisDilatacaoAmbientes'
                                value='NÃO'
                                checked={formData.perfisDilatacaoAmbientes === 'NÃO'}
                                onChange={handleChange}
                                error={formErrors.perfisDilatacaoAmbientes}
                            />
                            <Form.Radio
                                label='Não sei'
                                name='perfisDilatacaoAmbientes'
                                value='Não sei'
                                checked={formData.perfisDilatacaoAmbientes === 'Não sei'}
                                onChange={handleChange}
                                error={formErrors.perfisDilatacaoAmbientes}
                            />
                        </Form.Group>





                        <Form.Group grouped>
                            <label>7. Foram instalados perfis de dilatação/transição para área acima de 8mts? (para pisos laminados)</label>
                            <Form.Radio
                                label='Sim'
                                name='perfisDilatacao8mts'
                                value='SIM'
                                checked={formData.perfisDilatacao8mts === 'SIM'}
                                onChange={handleChange}
                                error={formErrors.perfisDilatacao8mts}
                            />
                            <Form.Radio
                                label='Não'
                                name='perfisDilatacao8mts'
                                value='NÃO'
                                checked={formData.perfisDilatacao8mts === 'NÃO'}
                                onChange={handleChange}
                                error={formErrors.perfisDilatacao8mts}
                            />
                            <Form.Radio
                                label='Não sei'
                                name='perfisDilatacao8mts'
                                value='Não sei'
                                checked={formData.perfisDilatacao8mts === 'Não sei'}
                                onChange={handleChange}
                                error={formErrors.perfisDilatacao8mts}
                            />
                        </Form.Group>



                        <Form.Group grouped>
                            <label>8. Como é feita a manutenção (limpeza) do piso?</label>
                            <Form.Input
                                name='manutencaoPiso'
                                value={formData.manutencaoPiso}
                                onChange={handleChange}
                                placeholder='Descreva o método de limpeza do piso'
                                error={formErrors.manutencaoPiso}
                                maxLength={240}
                            />
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field error={formErrors.observacoes}>
                                <label>Descreva com detalhes o motivo da solicitação</label>
                                <TextArea
                                    placeholder='Descreva com detalhes o motivo da solicitação'
                                    name='observacoes'
                                    value={formData.observacoes}
                                    onChange={handleChange}
                                    maxLength={3500}
                                    style={{ resize: 'none' }} // Desabilita o redimensionamento
                                />
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }} className="text-left text-gray-500">
                                    {formData.observacoes.length}/3500
                                </div>
                            </Form.Field>
                        </Form.Group>
                        <Form.Field error={!!(errors.uploadedFiles || fileError)}>
                            <label>Upload de Imagem</label>
                            <DropzoneComponent
                                maxFiles={5}
                                maxSize={5 * 1024 * 1024} // 5MB
                                accept={{
                                    'image/png': ['.png'],
                                    'image/jpeg': ['.jpeg'],
                                    'image/jpg': ['.jpg'],
                                    'application/pdf': ['.pdf'],
                                    'video/mp4': ['.mp4'],
                                    'video/quicktime': ['.mov'],
                                    // Adicione outros formatos conforme necessário
                                }}
                                onFilesChange={(files) => {
                                    setUploadedFiles(files);
                                    if (files.length === 0) {
                                        setFileError('Por favor, adicione ao menos um arquivo.');
                                    } else {
                                        setFileError('');
                                    }
                                }}
                            />
                            {fileError && <p style={{ color: 'red' }}>{fileError}</p>}
                        </Form.Field>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Button primary type='submit' style={{ marginBottom: '25px', width: '100%', margin: '5px' }} >
                                {loading_form_dados || isSubmitting ? (
                                    <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                                        <ModalLoading
                                            sending={isSubmitting}
                                            checked={isChecked}
                                        />
                                    </div>
                                ) : (

                                    <div> Confirmar</div>
                                )}

                            </Button>
                        </div>
                    </Segment>
                </Form>
                <Footer mobile />

            </MobileView>

        </>
    );
}

const mapStateToProps = (state) => ({
    form_info: state.FormReducer.form_info,
    loading_form_dados: state.FormReducer.loading_form_dados
});


export default connect(mapStateToProps, {
    FormEnviaDadosPisoAction,
})(FormPiso);
