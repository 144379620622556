import { useState } from 'react';
import Footer from "../../../Footer/Footer";
import NavBar from "../../../Navbar/NavBar";
import DropzoneComponent from '../formularios/Dropzone'
import { FormEnviaDadosForroPerfilAction } from '../../../../actions/FormActions';
import { Form, Segment, Button, Grid, TextArea, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import ModalLoading from './ModalLoading';
import { BrowserView, MobileView } from 'react-device-detect';



function FormEspacoForroPerfil(props) {
    const { FormEnviaDadosForroPerfilAction } = props;
    const { form_info, loading_form_dados } = props;

    let telefone = form_info?.telefone?.replace(/\s+/g, '');
    let fax = form_info?.fax?.replace(/\s+/g, '');
    const nomeConsumidor = form_info.nomeparc;
    const enderecoConsumidor = form_info.endereco;
    const bairroConsumidor = form_info.bairro;
    const cidadeConsumidor = form_info.cidade;
    const foneConsumidor = telefone?.length === 0 ? (fax?.length === 0 ? '' : fax) : telefone;
    const emailConsumidor = form_info.email;
    const cpfConsumidor = form_info.cgc_cpf;
    const nomeprod = form_info.desc_prod;

    const navigate = useNavigate();


    // State para controlar os erros
    const [formErrors, setFormErrors] = useState({});
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [fileError, setFileError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isChecked, setIsChecked] = useState(false);


    // States para armazenar os valores do formulário
    const [formData, setFormData] = useState({
        LocalInstalado: '',
        tipoCobertura: '',
        observacoes: '',
    });

    // Função para lidar com mudanças nos campos do formulário
    const handleChange = (e, { name, value }) => {
        setFormData({ ...formData, [name]: value });
        setFormErrors({ ...formErrors, [name]: false }); // Remove o erro ao corrigir
        console.log(`Campo ${name} alterado para: ${value}`); // Log do campo alterado
    };


    const errors = {};
    const handleSubmit = () => {

        // Se já estiver submetendo, não faça nada
        if (isSubmitting) {
            return;
        }


        // Verifica se os campos estão vazios e seta o estado de erro
        Object.keys(formData).forEach((field) => {
            if (!formData[field]) {
                errors[field] = true;
            }
        });
        console.log(errors)
        // Se houver erros, define os erros e não submete o formulário
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors); // Ativa o estado de erro
        } else {
            // Caso não tenha erros, envie o formulário
            if (uploadedFiles.length === 0) {
                setFileError('Por favor, adicione ao menos um arquivo.');
            } else {
                setFileError('');
                setIsSubmitting(true);

                FormEnviaDadosForroPerfilAction(form_info, formData, uploadedFiles).then(() => {
                    setIsChecked(true);

                    setTimeout(() => {
                        navigate('/assistencia/protocolo');
                        setIsSubmitting(false);  // Libera a função para ser chamada novamente após a conclusão
                    }, 4000);
                })
                console.log('Form enviado com sucesso!', formData, uploadedFiles);
            }

        }
    };


    return (
        <>
            <BrowserView>
                <div id="topo" />
                <NavBar />
                <Form onSubmit={handleSubmit}>
                    <Segment>
                        <h2>Assistencia EspaçoForro Forro/Peril</h2>
                        <hr></hr>
                        <p style={{ fontSize: '24px' }}>{nomeprod}</p>
                        <h3>Dados do Cliente:</h3>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>Nome</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Nome'
                                    name='nome'
                                    label={nomeConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Telefone</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Telefone'
                                    name='telefone'
                                    label={foneConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Email</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Email'
                                    name='email'
                                    label={emailConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>CPF</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='CPF'
                                    name='cpf'
                                    label={cpfConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>Endereço</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Endereço'
                                    name='endereco'
                                    label={enderecoConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Bairro</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Bairro'
                                    name='bairro'
                                    label={bairroConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Cidade</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Cidade'
                                    name='cidade'
                                    label={cidadeConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                        </Form.Group>
                        <h3>Questionário (Consultar Instalador)</h3>
                        <Grid>
                            <Grid.Column width={8}  >
                                <Form.Group grouped  >
                                    <label>1. Local em que está instalado</label>
                                    <Form.Radio
                                        label='Área Comercial'
                                        name='LocalInstalado'
                                        value='Área Comercial'
                                        checked={formData.LocalInstalado === 'Área Comercial'}
                                        onChange={handleChange}
                                        error={formErrors.LocalInstalado}
                                    />
                                    <Form.Radio
                                        label='Área Residencial'
                                        name='LocalInstalado'
                                        value='Área Residencial'
                                        checked={formData.LocalInstalado === 'Área Residencial'}
                                        onChange={handleChange}
                                        error={formErrors.LocalInstalado}
                                    />
                                </Form.Group>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Form.Group grouped  >
                                    <label>2. Tipo de Cobertura</label>
                                    <Form.Radio
                                        label='Fibrocimento'
                                        name='tipoCobertura'
                                        value='Fibrocimento'
                                        checked={formData.tipoCobertura === 'Fibrocimento'}
                                        onChange={handleChange}
                                        error={formErrors.tipoCobertura}
                                    />
                                    <Form.Radio
                                        label='Metálica'
                                        name='tipoCobertura'
                                        value='Metálica'
                                        checked={formData.tipoCobertura === 'Metálica'}
                                        onChange={handleChange}
                                        error={formErrors.tipoCobertura}
                                    />
                                    <Form.Radio
                                        label='Sob Laje'
                                        name='tipoCobertura'
                                        value='Sob Laje'
                                        checked={formData.tipoCobertura === 'Sob Laje'}
                                        onChange={handleChange}
                                        error={formErrors.tipoCobertura}
                                    />
                                    <Form.Radio
                                        label='Não sei'
                                        name='tipoCobertura'
                                        value='Não sei'
                                        checked={formData.tipoCobertura === 'Não sei'}
                                        onChange={handleChange}
                                        error={formErrors.naosei}
                                    />
                                </Form.Group>
                            </Grid.Column>
                        </Grid>
                        <Form.Group widths='equal'>
                            <Form.Field error={formErrors.observacoes}>
                                <label>Descreva com detalhes o motivo da solicitação</label>
                                <TextArea
                                    placeholder='Descreva com detalhes o motivo da solicitação'
                                    name='observacoes'
                                    value={formData.observacoes}
                                    onChange={handleChange}
                                    maxLength={3500}
                                    style={{ resize: 'none' }} // Desabilita o redimensionamento
                                />
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }} className="text-left text-gray-500">
                                    {formData.observacoes.length}/3500
                                </div>
                            </Form.Field>
                        </Form.Group>
                        <Form.Field error={!!(errors.uploadedFiles || fileError)}>
                            <label>Upload de Imagem</label>
                            <DropzoneComponent
                                maxFiles={5}
                                maxSize={5 * 1024 * 1024} // 5MB
                                accept={{
                                    'image/png': ['.png'],
                                    'image/jpeg': ['.jpeg'],
                                    'image/jpg': ['.jpg'],
                                    'application/pdf': ['.pdf'],
                                    'video/mp4': ['.mp4'],
                                    'video/quicktime': ['.mov'],
                                    // Adicione outros formatos conforme necessário
                                }}
                                onFilesChange={(files) => {
                                    setUploadedFiles(files);
                                    if (files.length === 0) {
                                        setFileError('Por favor, adicione ao menos um arquivo.');
                                    } else {
                                        setFileError('');
                                    }
                                }}
                            />
                            {fileError && <p style={{ color: 'red' }}>{fileError}</p>}
                        </Form.Field>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Button primary type='submit' style={{ marginBottom: '25px', width: '100%', margin: '5px' }} >
                                {loading_form_dados || isSubmitting ? (
                                    <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                                        <ModalLoading
                                            sending={isSubmitting}
                                            checked={isChecked}
                                        />
                                    </div>
                                ) : (

                                    <div> Confirmar</div>
                                )}

                            </Button>
                        </div>


                    </Segment>
                </Form>
                <Footer />
            </BrowserView>
            <MobileView>
                <div id="topo" />
                <NavBar mobile />
                <Form onSubmit={handleSubmit}>
                    <Segment>
                        <h2>Assistência EspaçoForro Forro/Peril</h2>
                        <hr></hr>
                        <p style={{ fontSize: '24px' }}>{nomeprod}</p>
                        <h3>Dados do Cliente:</h3>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>Nome</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Nome'
                                    name='nome'
                                    label={nomeConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Telefone</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Telefone'
                                    name='telefone'
                                    label={foneConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Email</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Email'
                                    name='email'
                                    label={emailConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>CPF</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='CPF'
                                    name='cpf'
                                    label={cpfConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>Endereço</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Endereço'
                                    name='endereco'
                                    label={enderecoConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Bairro</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Bairro'
                                    name='bairro'
                                    label={bairroConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Cidade</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Cidade'
                                    name='cidade'
                                    label={cidadeConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                        </Form.Group>
                        <h3>Questionário (Consultar Instalador)</h3>

                        <Form.Group grouped  >
                            <label>1. Local em que está instalado</label>
                            <Form.Radio
                                label='Área Comercial'
                                name='LocalInstalado'
                                value='Área Comercial'
                                checked={formData.LocalInstalado === 'Área Comercial'}
                                onChange={handleChange}
                                error={formErrors.LocalInstalado}
                            />
                            <Form.Radio
                                label='Área Residencial'
                                name='LocalInstalado'
                                value='Área Residencial'
                                checked={formData.LocalInstalado === 'Área Residencial'}
                                onChange={handleChange}
                                error={formErrors.LocalInstalado}
                            />
                        </Form.Group>


                        <Form.Group grouped  >
                            <label>2. Tipo de Cobertura</label>
                            <Form.Radio
                                label='Fibrocimento'
                                name='tipoCobertura'
                                value='Fibrocimento'
                                checked={formData.tipoCobertura === 'Fibrocimento'}
                                onChange={handleChange}
                                error={formErrors.tipoCobertura}
                            />
                            <Form.Radio
                                label='Metálica'
                                name='tipoCobertura'
                                value='Metálica'
                                checked={formData.tipoCobertura === 'Metálica'}
                                onChange={handleChange}
                                error={formErrors.tipoCobertura}
                            />
                            <Form.Radio
                                label='Sob Laje'
                                name='tipoCobertura'
                                value='Sob Laje'
                                checked={formData.tipoCobertura === 'Sob Laje'}
                                onChange={handleChange}
                                error={formErrors.tipoCobertura}
                            />
                            <Form.Radio
                                label='Não sei'
                                name='tipoCobertura'
                                value='Não sei'
                                checked={formData.tipoCobertura === 'Não sei'}
                                onChange={handleChange}
                                error={formErrors.naosei}
                            />
                        </Form.Group>

                        <Form.Group widths='equal'>
                            <Form.Field error={formErrors.observacoes}>
                                <label>Descreva com detalhes o motivo da solicitação</label>
                                <TextArea
                                    placeholder='Descreva com detalhes o motivo da solicitação'
                                    name='observacoes'
                                    value={formData.observacoes}
                                    onChange={handleChange}
                                    maxLength={3500}
                                    style={{ resize: 'none' }} // Desabilita o redimensionamento
                                />
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }} className="text-left text-gray-500">
                                    {formData.observacoes.length}/3500
                                </div>
                            </Form.Field>
                        </Form.Group>
                        <Form.Field error={!!(errors.uploadedFiles || fileError)}>
                            <label>Upload de Imagem</label>
                            <DropzoneComponent
                                maxFiles={5}
                                maxSize={5 * 1024 * 1024} // 5MB
                                accept={{
                                    'image/png': ['.png'],
                                    'image/jpeg': ['.jpeg'],
                                    'image/jpg': ['.jpg'],
                                    'application/pdf': ['.pdf'],
                                    'video/mp4': ['.mp4'],
                                    'video/quicktime': ['.mov'],
                                    // Adicione outros formatos conforme necessário
                                }}
                                onFilesChange={(files) => {
                                    setUploadedFiles(files);
                                    if (files.length === 0) {
                                        setFileError('Por favor, adicione ao menos um arquivo.');
                                    } else {
                                        setFileError('');
                                    }
                                }}
                            />
                            {fileError && <p style={{ color: 'red' }}>{fileError}</p>}
                        </Form.Field>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Button primary type='submit' style={{ marginBottom: '25px', width: '100%', margin: '5px' }} >
                                {loading_form_dados || isSubmitting ? (
                                    <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                                        <ModalLoading
                                            sending={isSubmitting}
                                            checked={isChecked}
                                        />
                                    </div>
                                ) : (

                                    <div> Confirmar</div>
                                )}

                            </Button>
                        </div>


                    </Segment>
                </Form>
                <Footer mobile />
            </MobileView>


        </>
    );
}

const mapStateToProps = (state) => ({
    form_info: state.FormReducer.form_info,
    loading_form_dados: state.FormReducer.loading_form_dados
});


export default connect(mapStateToProps, {
    FormEnviaDadosForroPerfilAction
})(FormEspacoForroPerfil);