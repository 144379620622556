import { useState } from 'react';
import DropzoneComponent from '../formularios/Dropzone'
import Footer from "../../../Footer/Footer";
import NavBar from "../../../Navbar/NavBar";
import { Form, Segment, Button, Grid, TextArea } from 'semantic-ui-react';
import { BrowserView, MobileView } from 'react-device-detect';
import { connect } from 'react-redux';
import ModalLoading from './ModalLoading';
import { FormEnviaDadosPainelColaRodapeDeckAction } from '../../../../actions/FormActions';
import { useNavigate } from 'react-router';


function FormRodapeColaPainelDeck(props) {

    const { form_info, loading_form_dados } = props;
    const { FormEnviaDadosPainelColaRodapeDeckAction } = props;
    const navigate = useNavigate();


    const [formErrors, setFormErrors] = useState({});
    const [errors, setErrors] = useState({});
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [fileError, setFileError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [formData, setFormData] = useState({
        localPiso: '',
        temUmidade: '',
        metodoInstalacao: '',
        observacoes: '',
        limpeza: '',
    });

    const handleChange = (e, { name, value }) => {
        setFormData({ ...formData, [name]: value });
        setFormErrors({ ...formErrors, [name]: false }); // Remove o erro ao corrigir
        console.log(`Campo ${name} alterado para: ${value}`); // Log do campo alterado
    };

    let telefone = form_info?.telefone?.replace(/\s+/g, '');
    let fax = form_info?.fax?.replace(/\s+/g, '');

    const nomeConsumidor = form_info.nomeparc;
    const enderecoConsumidor = form_info.endereco;
    const bairroConsumidor = form_info.bairro;
    const cidadeConsumidor = form_info.cidade;
    const foneConsumidor = telefone?.length === 0 ? (fax?.length === 0 ? '' : fax) : telefone;
    const emailConsumidor = form_info.email;
    const cpfConsumidor = form_info.cgc_cpf;
    const nomeprod = form_info.desc_prod;
    let categoria = form_info.categoria;
    let primeiraPalavra = categoria.split(' ')[0];

    // Função para validação
    const validateForm = () => {
        const errors = {};
        // Verificação para campos obrigatórios de Radio
        if (!formData.localPiso) errors.localPiso = true;
        if (!formData.temUmidade) errors.temUmidade = true;
        if (!formData.metodoInstalacao) errors.metodoInstalacao = true;
        setFormErrors(errors); // Definir os erros
        return Object.keys(errors).length === 0; // Retorna true se não houver erros
    };

    const handleSubmit = () => {
        // Se já estiver submetendo, não faça nada
        if (isSubmitting) {
            return;
        }
        const errors = {};

        // Verifica se os campos estão vazios e seta o estado de erro
        if (primeiraPalavra === "COLAS") {
            // Apenas verifica se o campo "observacoes" está vazio
            if (!formData.observacoes) {
                errors.observacoes = true;
            }
        } else {
            // Se não for "COLAS", verifica todos os campos
            Object.keys(formData).forEach((field) => {
                if (!formData[field]) {
                    errors[field] = true;
                }
            });
        }

        // Se houver erros, define os erros e não submete o formulário
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors); // Ativa o estado de erro
        } else {
            // Caso não tenha erros, envie o formulário
            if (uploadedFiles.length === 0) {
                setFileError('Por favor, adicione ao menos um arquivo.');
            } else {
                setFileError('');
                setIsSubmitting(true);
                FormEnviaDadosPainelColaRodapeDeckAction(form_info, formData, uploadedFiles).then(() => {
                    setIsChecked(true);
                    setTimeout(() => {
                        navigate('/assistencia/protocolo');
                        setIsSubmitting(false);
                    }, 4000);
                })
            }

        }
    };

    return (
        <>
            <BrowserView>
                <div id="topo" />
                <NavBar />
                <Form onSubmit={handleSubmit}>
                    <Segment>


                        {/* Verifica se a primeira palavra é "COLAS" */}
                        {primeiraPalavra === "COLAS" ? (
                            <>
                                <h3>Assistência EspaçoFloor Colas</h3>
                                <hr />
                                <p style={{ fontSize: '24px' }}>{nomeprod}</p>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <label>Nome</label>
                                        <Form.Field
                                            fluid="true"
                                            placeholder='Nome'
                                            name='nome'
                                            label={nomeConsumidor}
                                            readOnly
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Telefone</label>
                                        <Form.Field
                                            fluid="true"
                                            placeholder='Telefone'
                                            name='telefone'
                                            label={foneConsumidor}
                                            readOnly
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Email</label>
                                        <Form.Field
                                            fluid="true"
                                            placeholder='Email'
                                            name='email'
                                            label={emailConsumidor}
                                            readOnly
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>CPF</label>
                                        <Form.Field
                                            fluid="true"
                                            placeholder='CPF'
                                            name='cpf'
                                            label={cpfConsumidor}
                                            readOnly
                                        />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <label>Endereço</label>
                                        <Form.Field
                                            fluid="true"
                                            placeholder='Endereço'
                                            name='endereco'
                                            label={enderecoConsumidor}
                                            readOnly
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Bairro</label>
                                        <Form.Field
                                            fluid="true"
                                            placeholder='Bairro'
                                            name='bairro'
                                            label={bairroConsumidor}
                                            readOnly
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Cidade</label>
                                        <Form.Field
                                            fluid="true"
                                            placeholder='Cidade'
                                            name='cidade'
                                            label={cidadeConsumidor}
                                            readOnly
                                        />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field error={formErrors.observacoes}>
                                        <label>Descreva com detalhes o motivo da solicitação</label>
                                        <TextArea
                                            placeholder='Descreva com detalhes o motivo da solicitação'
                                            name='observacoes'
                                            value={formData.observacoes}
                                            onChange={handleChange}
                                            maxLength={3500}
                                            style={{ resize: 'none' }} // Desabilita o redimensionamento
                                        />
                                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }} className="text-left text-gray-500">
                                            {formData.observacoes.length}/3500
                                        </div>
                                    </Form.Field>
                                </Form.Group>
                                <Form.Field error={!!(errors.uploadedFiles || fileError)}>
                                    <label>Upload de Imagem</label>
                                    <DropzoneComponent
                                        maxFiles={5}
                                        maxSize={5 * 1024 * 1024} // 5MB
                                        accept={{
                                            'image/png': ['.png'],
                                            'image/jpeg': ['.jpeg'],
                                            'image/jpg': ['.jpg'],
                                            'application/pdf': ['.pdf'],
                                            'video/mp4': ['.mp4'],
                                            // Adicione outros formatos conforme necessário
                                        }}
                                        onFilesChange={(files) => {
                                            setUploadedFiles(files);
                                            if (files.length === 0) {
                                                setFileError('Por favor, adicione ao menos um arquivo.');
                                            } else {
                                                setFileError('');
                                            }
                                        }}
                                    />
                                    {fileError && <p style={{ color: 'red' }}>{fileError}</p>}
                                </Form.Field>
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <Button primary type='submit' style={{ marginBottom: '25px', width: '100%', margin: '5px' }} >
                                        {loading_form_dados || isSubmitting ? (
                                            <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                                                <ModalLoading
                                                    sending={isSubmitting}
                                                    checked={isChecked}
                                                />
                                            </div>
                                        ) : (

                                            <div> Confirmar</div>
                                        )}
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <>

                                <h3>Assistência EspaçoFloor Decks Painéis e Rodapés</h3>
                                <hr />
                                <p style={{ fontSize: '24px' }}>{nomeprod}</p>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <label>Nome</label>
                                        <Form.Field
                                            fluid="true"
                                            placeholder='Nome'
                                            name='nome'
                                            label={nomeConsumidor}
                                            readOnly
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Telefone</label>
                                        <Form.Field
                                            fluid="true"
                                            placeholder='Telefone'
                                            name='telefone'
                                            label={foneConsumidor}
                                            readOnly
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Email</label>
                                        <Form.Field
                                            fluid="true"
                                            placeholder='Email'
                                            name='email'
                                            label={emailConsumidor}
                                            readOnly
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>CPF</label>
                                        <Form.Field
                                            fluid="true"
                                            placeholder='CPF'
                                            name='cpf'
                                            label={cpfConsumidor}
                                            readOnly
                                        />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <label>Endereço</label>
                                        <Form.Field
                                            fluid="true"
                                            placeholder='Endereço'
                                            name='endereco'
                                            label={enderecoConsumidor}
                                            readOnly
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Bairro</label>
                                        <Form.Field
                                            fluid="true"
                                            placeholder='Bairro'
                                            name='bairro'
                                            label={bairroConsumidor}
                                            readOnly
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Cidade</label>
                                        <Form.Field
                                            fluid="true"
                                            placeholder='Cidade'
                                            name='cidade'
                                            label={cidadeConsumidor}
                                            readOnly
                                        />
                                    </Form.Field>
                                </Form.Group>
                                <hr style={{ opacity: '0.5' }}></hr>
                                <Grid style={{ marginTop: '15px' }}>
                                    <Grid.Column width={5} style={{ overflow: 'hidden' }} >
                                        <Form.Group grouped style={{ overflow: 'hidden' }} >
                                            <label>1. Local em que está instalado.</label>
                                            <Form.Radio
                                                label='Pavimento Térreo'
                                                name='localPiso'
                                                value='PAVIMENTO TÉRREO'
                                                checked={formData.localPiso === 'PAVIMENTO TÉRREO'}
                                                onChange={handleChange}
                                                error={formErrors.localPiso}
                                            />
                                            <Form.Radio
                                                label='Sobre piso de madeira'
                                                name='localPiso'
                                                value='SOBRE PISO DE MADEIRA'
                                                checked={formData.localPiso === 'SOBRE PISO DE MADEIRA'}
                                                onChange={handleChange}
                                                error={formErrors.localPiso}
                                            />
                                            <Form.Radio
                                                label='Sobre cerâmico'
                                                name='localPiso'
                                                value='SOBRE CERÂMICO'
                                                checked={formData.localPiso === 'SOBRE CERÂMICO'}
                                                onChange={handleChange}
                                                error={formErrors.localPiso}
                                            />
                                            <Form.Radio
                                                label='Sobre cimento'
                                                name='localPiso'
                                                value='SOBRE CIMENTO'
                                                checked={formData.localPiso === 'SOBRE CIMENTO'}
                                                onChange={handleChange}
                                                error={formErrors.localPiso}
                                            />
                                            <Form.Radio
                                                label='Não sei'
                                                name='localPiso'
                                                value='Não sei'
                                                checked={formData.localPiso === 'Não sei'}
                                                onChange={handleChange}
                                                error={formErrors.localPiso}
                                            />
                                        </Form.Group>
                                    </Grid.Column>

                                    <Grid.Column width={5}>
                                        <Form.Group grouped>
                                            <label>2. Tem umidade no local?</label>
                                            <Form.Radio
                                                label='Sim'
                                                name='temUmidade'
                                                value='SIM'
                                                checked={formData.temUmidade === 'SIM'}
                                                onChange={handleChange}
                                                error={formErrors.temUmidade}
                                            />
                                            <Form.Radio
                                                label='Não'
                                                name='temUmidade'
                                                value='NÃO'
                                                checked={formData.temUmidade === 'NÃO'}
                                                onChange={handleChange}
                                                error={formErrors.temUmidade}
                                            />
                                            <Form.Radio
                                                label='Não sei'
                                                name='temUmidade'
                                                value='Não sei'
                                                checked={formData.temUmidade === 'Não sei'}
                                                onChange={handleChange}
                                                error={formErrors.temUmidade}
                                            />
                                        </Form.Group>
                                    </Grid.Column>
                                    <Form.Group grouped>
                                        <label>3. Método de instalação.</label>
                                        <Form.Radio
                                            label='Prego'
                                            name='metodoInstalacao'
                                            value='Prego'
                                            checked={formData.metodoInstalacao === 'Prego'}
                                            onChange={handleChange}
                                            error={formErrors.metodoInstalacao}
                                        />
                                        <Form.Radio
                                            label='Cola'
                                            name='metodoInstalacao'
                                            value='Cola'
                                            checked={formData.metodoInstalacao === 'Cola'}
                                            onChange={handleChange}
                                            error={formErrors.metodoInstalacao}
                                        /><Form.Radio
                                            label='Bucha/Parafuso'
                                            name='metodoInstalacao'
                                            value='Bucha/Parafuso'
                                            checked={formData.metodoInstalacao === 'Bucha/Parafuso'}
                                            onChange={handleChange}
                                            error={formErrors.metodoInstalacao}
                                        />
                                        <Form.Radio
                                            label='Não sei'
                                            name='metodoInstalacao'
                                            value='Não sei'
                                            checked={formData.metodoInstalacao === 'Não sei'}
                                            onChange={handleChange}
                                            error={formErrors.metodoInstalacao}
                                        />
                                    </Form.Group>
                                </Grid>
                                <Form.Field error={formErrors.limpeza} style={{ resize: 'none', marginTop: '15px' }}>
                                    <label>4. Como é feita a manutenção(limpeza)?</label>
                                    <TextArea
                                        placeholder='Nos conte como é feita a manutenção...'
                                        name='limpeza'
                                        value={formData.limpeza}
                                        onChange={handleChange}
                                        maxLength={250}
                                        style={{ resize: 'none' }}
                                    />
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }} className="text-left text-gray-500">
                                        {formData.limpeza.length}/250
                                    </div>
                                </Form.Field>
                                <Form.Field error={formErrors.observacoes} style={{ resize: 'none', marginTop: '15px' }}>
                                    <label>Descreva com detalhes o motivo da solicitação</label>
                                    <TextArea
                                        placeholder='Descreva com detalhes o motivo da solicitação'
                                        name='observacoes'
                                        value={formData.observacoes}
                                        onChange={handleChange}
                                        maxLength={3500}
                                        style={{ resize: 'none' }}
                                    />
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }} className="text-left text-gray-500">
                                        {formData.observacoes.length}/3500
                                    </div>
                                </Form.Field>
                                <Form.Field error={!!(errors.uploadedFiles || fileError)}>
                                    <label>Upload de Imagem</label>
                                    <DropzoneComponent
                                        maxFiles={5}
                                        maxSize={5 * 1024 * 1024} // 5MB
                                        accept={{
                                            'image/png': ['.png'],
                                            'image/jpeg': ['.jpeg'],
                                            'image/jpg': ['.jpg'],
                                            'application/pdf': ['.pdf'],
                                            'video/mp4': ['.mp4'],
                                            'video/quicktime': ['.mov'],
                                            // Adicione outros formatos conforme necessário
                                        }}
                                        onFilesChange={(files) => {
                                            setUploadedFiles(files);
                                            if (files.length === 0) {
                                                setFileError('Por favor, adicione ao menos um arquivo.');
                                            } else {
                                                setFileError('');
                                            }
                                        }}
                                    />
                                    {fileError && <p style={{ color: 'red' }}>{fileError}</p>}
                                </Form.Field>
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <Button primary type='submit' style={{ marginBottom: '25px', width: '100%', margin: '5px' }} >
                                        {loading_form_dados || isSubmitting ? (
                                            <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                                                <ModalLoading
                                                    sending={isSubmitting}
                                                    checked={isChecked}
                                                />
                                            </div>
                                        ) : (

                                            <div> Confirmar</div>
                                        )}

                                    </Button>
                                </div>
                            </>
                        )}
                    </Segment>
                </Form >
                <Footer />
            </BrowserView>

            <MobileView>
                <div id="topo" />
                <NavBar mobile />
                <Form onSubmit={handleSubmit}>
                    <Segment>


                        {/* Verifica se a primeira palavra é "COLAS" */}
                        {primeiraPalavra === "COLAS" ? (
                            <>
                                <h3>Assistência EspaçoFloor Colas</h3>
                                <hr />
                                <p style={{ fontSize: '24px' }}>{nomeprod}</p>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <label>Nome</label>
                                        <Form.Field
                                            fluid="true"
                                            placeholder='Nome'
                                            name='nome'
                                            label={nomeConsumidor}
                                            readOnly
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Telefone</label>
                                        <Form.Field
                                            fluid="true"
                                            placeholder='Telefone'
                                            name='telefone'
                                            label={foneConsumidor}
                                            readOnly
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Email</label>
                                        <Form.Field
                                            fluid="true"
                                            placeholder='Email'
                                            name='email'
                                            label={emailConsumidor}
                                            readOnly
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>CPF</label>
                                        <Form.Field
                                            fluid="true"
                                            placeholder='CPF'
                                            name='cpf'
                                            label={cpfConsumidor}
                                            readOnly
                                        />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <label>Endereço</label>
                                        <Form.Field
                                            fluid="true"
                                            placeholder='Endereço'
                                            name='endereco'
                                            label={enderecoConsumidor}
                                            readOnly
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Bairro</label>
                                        <Form.Field
                                            fluid="true"
                                            placeholder='Bairro'
                                            name='bairro'
                                            label={bairroConsumidor}
                                            readOnly
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Cidade</label>
                                        <Form.Field
                                            fluid="true"
                                            placeholder='Cidade'
                                            name='cidade'
                                            label={cidadeConsumidor}
                                            readOnly
                                        />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field error={formErrors.observacoes}>
                                        <label>Descreva com detalhes o motivo da solicitação</label>
                                        <TextArea
                                            placeholder='Descreva com detalhes o motivo da solicitação'
                                            name='observacoes'
                                            value={formData.observacoes}
                                            onChange={handleChange}
                                            maxLength={3500}
                                            style={{ resize: 'none' }} // Desabilita o redimensionamento
                                        />
                                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }} className="text-left text-gray-500">
                                            {formData.observacoes.length}/3500
                                        </div>
                                    </Form.Field>
                                </Form.Group>
                                <Form.Field error={!!(errors.uploadedFiles || fileError)}>
                                    <label>Upload de Imagem</label>
                                    <DropzoneComponent
                                        maxFiles={5}
                                        maxSize={5 * 1024 * 1024} // 5MB
                                        accept={{
                                            'image/png': ['.png'],
                                            'image/jpeg': ['.jpeg'],
                                            'image/jpg': ['.jpg'],
                                            'application/pdf': ['.pdf'],
                                            'video/mp4': ['.mp4'],
                                            'video/quicktime': ['.mov'],
                                            // Adicione outros formatos conforme necessário
                                        }}
                                        onFilesChange={(files) => {
                                            setUploadedFiles(files);
                                            if (files.length === 0) {
                                                setFileError('Por favor, adicione ao menos um arquivo.');
                                            } else {
                                                setFileError('');
                                            }
                                        }}
                                    />
                                    {fileError && <p style={{ color: 'red' }}>{fileError}</p>}
                                </Form.Field>
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <Button primary type='submit' style={{ marginBottom: '25px', width: '100%', margin: '5px' }} >
                                        {loading_form_dados || isSubmitting ? (
                                            <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                                                <ModalLoading
                                                    sending={isSubmitting}
                                                    checked={isChecked}
                                                />
                                            </div>
                                        ) : (

                                            <div> Confirmar</div>
                                        )}

                                    </Button>
                                </div>
                            </>
                        ) : (
                            <>
                                <h3>Assistência EspaçoFloor Decks Painéis e Rodapés</h3>
                                <hr />
                                <p style={{ fontSize: '24px' }}>{nomeprod}</p>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <label>Nome</label>
                                        <Form.Field
                                            fluid="true"
                                            placeholder='Nome'
                                            name='nome'
                                            label={nomeConsumidor}
                                            readOnly
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Telefone</label>
                                        <Form.Field
                                            fluid="true"
                                            placeholder='Telefone'
                                            name='telefone'
                                            label={foneConsumidor}
                                            readOnly
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Email</label>
                                        <Form.Field
                                            fluid="true"
                                            placeholder='Email'
                                            name='email'
                                            label={emailConsumidor}
                                            readOnly
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>CPF</label>
                                        <Form.Field
                                            fluid="true"
                                            placeholder='CPF'
                                            name='cpf'
                                            label={cpfConsumidor}
                                            readOnly
                                        />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <label>Endereço</label>
                                        <Form.Field
                                            fluid="true"
                                            placeholder='Endereço'
                                            name='endereco'
                                            label={enderecoConsumidor}
                                            readOnly
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Bairro</label>
                                        <Form.Field
                                            fluid="true"
                                            placeholder='Bairro'
                                            name='bairro'
                                            label={bairroConsumidor}
                                            readOnly
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Cidade</label>
                                        <Form.Field
                                            fluid="true"
                                            placeholder='Cidade'
                                            name='cidade'
                                            label={cidadeConsumidor}
                                            readOnly
                                        />
                                    </Form.Field>
                                </Form.Group>
                                <hr></hr>
                                <Form.Group grouped style={{ overflow: 'hidden' }} >
                                    <label>1. Local em que está instalado.</label>
                                    <Form.Radio
                                        label='Pavimento Térreo'
                                        name='localPiso'
                                        value='PAVIMENTO TÉRREO'
                                        checked={formData.localPiso === 'PAVIMENTO TÉRREO'}
                                        onChange={handleChange}
                                        error={formErrors.localPiso}
                                    />
                                    <Form.Radio
                                        label='Sobre piso de madeira'
                                        name='localPiso'
                                        value='SOBRE PISO DE MADEIRA'
                                        checked={formData.localPiso === 'SOBRE PISO DE MADEIRA'}
                                        onChange={handleChange}
                                        error={formErrors.localPiso}
                                    />
                                    <Form.Radio
                                        label='Sobre cerâmico'
                                        name='localPiso'
                                        value='SOBRE CERÂMICO'
                                        checked={formData.localPiso === 'SOBRE CERÂMICO'}
                                        onChange={handleChange}
                                        error={formErrors.localPiso}
                                    />
                                    <Form.Radio
                                        label='Sobre cimento'
                                        name='localPiso'
                                        value='SOBRE CIMENTO'
                                        checked={formData.localPiso === 'SOBRE CIMENTO'}
                                        onChange={handleChange}
                                        error={formErrors.localPiso}
                                    />
                                    <Form.Radio
                                        label='Não sei'
                                        name='localPiso'
                                        value='Não sei'
                                        checked={formData.localPiso === 'Não sei'}
                                        onChange={handleChange}
                                        error={formErrors.localPiso}
                                    />
                                </Form.Group>



                                <Form.Group grouped>
                                    <label>2. Tem umidade no local?</label>
                                    <Form.Radio
                                        label='Sim'
                                        name='temUmidade'
                                        value='SIM'
                                        checked={formData.temUmidade === 'SIM'}
                                        onChange={handleChange}
                                        error={formErrors.temUmidade}
                                    />
                                    <Form.Radio
                                        label='Não'
                                        name='temUmidade'
                                        value='NÃO'
                                        checked={formData.temUmidade === 'NÃO'}
                                        onChange={handleChange}
                                        error={formErrors.temUmidade}
                                    />
                                    <Form.Radio
                                        label='Não sei'
                                        name='temUmidade'
                                        value='Não sei'
                                        checked={formData.temUmidade === 'Não sei'}
                                        onChange={handleChange}
                                        error={formErrors.temUmidade}
                                    />
                                </Form.Group>

                                <Form.Group grouped>
                                    <label>3. Método de instalação.</label>
                                    <Form.Radio
                                        label='Prego'
                                        name='metodoInstalacao'
                                        value='Prego'
                                        checked={formData.metodoInstalacao === 'Prego'}
                                        onChange={handleChange}
                                        error={formErrors.metodoInstalacao}
                                    />
                                    <Form.Radio
                                        label='Cola'
                                        name='metodoInstalacao'
                                        value='Cola'
                                        checked={formData.metodoInstalacao === 'Cola'}
                                        onChange={handleChange}
                                        error={formErrors.metodoInstalacao}
                                    /><Form.Radio
                                        label='Bucha/Parafuso'
                                        name='metodoInstalacao'
                                        value='Bucha/Parafuso'
                                        checked={formData.metodoInstalacao === 'Bucha/Parafuso'}
                                        onChange={handleChange}
                                        error={formErrors.metodoInstalacao}
                                    />
                                    <Form.Radio
                                        label='Não sei'
                                        name='metodoInstalacao'
                                        value='Não sei'
                                        checked={formData.metodoInstalacao === 'Não sei'}
                                        onChange={handleChange}
                                        error={formErrors.metodoInstalacao}
                                    />
                                </Form.Group>

                                <Form.Field error={formErrors.limpeza} style={{ resize: 'none', marginTop: '15px' }}>
                                    <label>4. Como é feita a manutenção(limpeza)?</label>
                                    <TextArea
                                        placeholder='Nos conte como é feita a manutenção...'
                                        name='limpeza'
                                        value={formData.limpeza}
                                        onChange={handleChange}
                                        maxLength={250}
                                        style={{ resize: 'none' }}
                                    />
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }} className="text-left text-gray-500">
                                        {formData.limpeza.length}/250
                                    </div>
                                </Form.Field>
                                <Form.Field error={formErrors.observacoes} style={{ resize: 'none', marginTop: '15px' }}>
                                    <label>Descreva com detalhes o motivo da solicitação</label>
                                    <TextArea
                                        placeholder='Descreva com detalhes o motivo da solicitação'
                                        name='observacoes'
                                        value={formData.observacoes}
                                        onChange={handleChange}
                                        maxLength={3500}
                                        style={{ resize: 'none' }}
                                    />
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }} className="text-left text-gray-500">
                                        {formData.observacoes.length}/3500
                                    </div>
                                </Form.Field>
                                <Form.Field error={!!(errors.uploadedFiles || fileError)}>
                                    <label>Upload de Imagem</label>
                                    <DropzoneComponent
                                        maxFiles={5}
                                        maxSize={5 * 1024 * 1024} // 5MB
                                        accept={{
                                            'image/png': ['.png'],
                                            'image/jpeg': ['.jpeg'],
                                            'image/jpg': ['.jpg'],
                                            'application/pdf': ['.pdf'],
                                            'video/mp4': ['.mp4'],
                                            'video/quicktime': ['.mov'],
                                            // Adicione outros formatos conforme necessário
                                        }}
                                        onFilesChange={(files) => {
                                            setUploadedFiles(files);
                                            if (files.length === 0) {
                                                setFileError('Por favor, adicione ao menos um arquivo.');
                                            } else {
                                                setFileError('');
                                            }
                                        }}
                                    />
                                    {fileError && <p style={{ color: 'red' }}>{fileError}</p>}
                                </Form.Field>
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <Button primary type='submit' style={{ marginBottom: '25px', width: '100%', margin: '5px' }} >
                                        {loading_form_dados || isSubmitting ? (
                                            <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                                                <ModalLoading
                                                    sending={isSubmitting}
                                                    checked={isChecked}
                                                />
                                            </div>
                                        ) : (

                                            <div> Confirmar</div>
                                        )}

                                    </Button>
                                </div>
                            </>
                        )}
                    </Segment>
                </Form >
                <Footer mobile />
            </MobileView>
        </>
    );
}

const mapStateToProps = state => ({
    form_info: state.FormReducer.form_info,
    form_padrao: state.ItensNotaReducer.form_padrao,
    loading_form_dados: state.FormReducer.loading_form_dados
});

export default connect(mapStateToProps, {
    FormEnviaDadosPainelColaRodapeDeckAction
})(FormRodapeColaPainelDeck);